/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AddressBookTabs = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="address-book-tabs__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-address-book-tabs'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16Zm-16 72h16v48h-16Zm16-16h-16V48h16ZM48 48h128v160H48Zm160 160h-16v-40h16v40Zm-56.25-42a39.76 39.76 0 0 0-17.19-23.34 32 32 0 1 0-45.12 0A39.84 39.84 0 0 0 72.25 166a8 8 0 0 0 15.5 4c2.64-10.25 13.06-18 24.25-18s21.62 7.73 24.25 18a8 8 0 1 0 15.5-4ZM96 120a16 16 0 1 1 16 16 16 16 0 0 1-16-16Z" /></Svg>;
export default AddressBookTabs;