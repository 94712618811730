/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AirplaneTakeoff = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="airplane-takeoff-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-airplane-takeoff-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m240 91.64-147.41 88a32 32 0 0 1-38-4.32L18.53 140a8 8 0 0 1 2.32-13.19l3.15-1.54L55.79 136 88 116.51 58.65 88a8 8 0 0 1 2.2-13.3L68 72l57.53 21.17 54.84-32.75a32 32 0 0 1 41 7.32Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M176 216a8 8 0 0 1-8 8H24a8 8 0 0 1 0-16h144a8 8 0 0 1 8 8Zm71.86-122.85a8 8 0 0 1-3.76 5.39l-147.41 88a40.18 40.18 0 0 1-20.26 5.52 39.78 39.78 0 0 1-27.28-10.87l-.12-.12L13 145.8a16 16 0 0 1 4.49-26.21l3-1.47a8 8 0 0 1 6.08-.4l28.26 9.54L75 115.06 53.17 93.87A16 16 0 0 1 57.7 67.4l.32-.13 7.15-2.71a8 8 0 0 1 5.59 0l53.94 19.82 51.57-30.78a39.82 39.82 0 0 1 51.28 9.12l.12.15 18.64 23.89a8 8 0 0 1 1.55 6.39Zm-19.74-3.7-13-16.67a23.88 23.88 0 0 0-30.68-5.42l-54.8 32.72a8.06 8.06 0 0 1-6.87.64L68 80.58l-4 1.53.21.2 29.36 28.49a8 8 0 0 1-1.43 12.58l-32.21 19.49a8 8 0 0 1-6.7.73l-28.67-9.67-.19.1-.37.17a.71.71 0 0 1 .13.12l36 35.26a23.85 23.85 0 0 0 28.42 3.18Z" /></Svg>;
export default AirplaneTakeoff;