/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AddressBookTabs = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="address-book-tabs-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-address-book-tabs-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16Zm-24 16h24v40h-24Zm0 56h24v48h-24Zm-38 71.75a8 8 0 0 1-9.74-5.76c-2.63-10.26-13.06-18-24.25-18s-21.61 7.74-24.25 18a8 8 0 0 1-15.5-4 39.84 39.84 0 0 1 17.19-23.34 32 32 0 1 1 45.12 0A39.76 39.76 0 0 1 151.75 166a8 8 0 0 1-5.75 9.75ZM208 208h-24v-40h24v40Zm-80-88a16 16 0 1 1-16-16 16 16 0 0 1 16 16Z" /></Svg>;
export default AddressBookTabs;