/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Alien = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="alien__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-alien'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 16a96.11 96.11 0 0 0-96 96c0 24 12.56 55.06 33.61 83 21.18 28.15 44.5 45 62.39 45s41.21-16.81 62.39-45c21.05-28 33.61-59 33.61-83a96.11 96.11 0 0 0-96-96Zm49.61 169.42C160.24 208.49 140.31 224 128 224s-32.24-15.51-49.61-38.58C59.65 160.5 48 132.37 48 112a80 80 0 0 1 160 0c0 20.37-11.65 48.5-30.39 73.42ZM120 136a40 40 0 0 0-40-40 16 16 0 0 0-16 16 40 40 0 0 0 40 40 16 16 0 0 0 16-16Zm-40-24a24 24 0 0 1 24 24 24 24 0 0 1-24-24Zm96-16a40 40 0 0 0-40 40 16 16 0 0 0 16 16 40 40 0 0 0 40-40 16 16 0 0 0-16-16Zm-24 40a24 24 0 0 1 24-24 24 24 0 0 1-24 24Zm0 48a8 8 0 0 1-8 8h-32a8 8 0 0 1 0-16h32a8 8 0 0 1 8 8Z" /></Svg>;
export default Alien;