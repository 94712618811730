/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ApproximateEquals = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="approximate-equals-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-approximate-equals-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M220.62 154.57a6 6 0 0 1-.79 8.45c-16.87 14-32 19-45.75 19-18.19 0-34.13-8.66-48.94-16.71-26-14.12-48.44-26.31-81.31 1a6 6 0 1 1-7.66-9.31c39.13-32.46 68.65-16.42 94.69-2.27s48.44 26.31 81.31-1a6 6 0 0 1 8.45.84ZM43.83 102.22c32.87-27.27 55.32-15.08 81.31-1 14.81 8 30.75 16.71 48.94 16.71 13.79 0 28.88-5 45.75-19a6 6 0 0 0-7.66-9.24c-32.87 27.27-55.32 15.08-81.31 1S75.3 60.52 36.17 93a6 6 0 0 0 7.66 9.24Z" /></Svg>;
export default ApproximateEquals;