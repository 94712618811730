/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArrowArcRight = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="arrow-arc-right-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-arrow-arc-right-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M236 88v64a4 4 0 0 1-4 4h-64a4 4 0 0 1 0-8h54.3l-29.24-29A92 92 0 0 0 36 184a4 4 0 0 1-8 0 100 100 0 0 1 170.71-70.71l29.29 29.1V88a4 4 0 0 1 8 0Z" /></Svg>;
export default ArrowArcRight;