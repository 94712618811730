/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AlignLeft = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="align-left-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-align-left-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M52 40v176a12 12 0 0 1-24 0V40a12 12 0 0 1 24 0Zm16 60V64a20 20 0 0 1 20-20h88a20 20 0 0 1 20 20v36a20 20 0 0 1-20 20H88a20 20 0 0 1-20-20Zm24-4h80V68H92Zm144 60v36a20 20 0 0 1-20 20H88a20 20 0 0 1-20-20v-36a20 20 0 0 1 20-20h128a20 20 0 0 1 20 20Zm-24 4H92v28h120Z" /></Svg>;
export default AlignLeft;