/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AlignCenterHorizontalSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="align-center-horizontal-simple-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-align-center-horizontal-simple-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 76h-68V48a12 12 0 0 0-24 0v28H48a20 20 0 0 0-20 20v64a20 20 0 0 0 20 20h68v28a12 12 0 0 0 24 0v-28h68a20 20 0 0 0 20-20V96a20 20 0 0 0-20-20Zm-4 80H52v-56h152Z" /></Svg>;
export default AlignCenterHorizontalSimple;