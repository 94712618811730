/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Ambulance = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="ambulance-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-ambulance-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M84 120a4 4 0 0 1 4-4h20V96a4 4 0 0 1 8 0v20h20a4 4 0 0 1 0 8h-20v20a4 4 0 0 1-8 0v-20H88a4 4 0 0 1-4-4Zm168 0v64a12 12 0 0 1-12 12h-20.29a28 28 0 0 1-55.42 0h-56.58a28 28 0 0 1-55.42 0H32a12 12 0 0 1-12-12V72a12 12 0 0 1 12-12h152a4 4 0 0 1 4 4v12h38.58a12 12 0 0 1 11.15 7.54l14 35A4 4 0 0 1 252 120Zm-64-36v32h54.09L230.3 86.51a4 4 0 0 0-3.72-2.51ZM32 188h20.29a28 28 0 0 1 55.42 0h56.58A28.05 28.05 0 0 1 180 166.71V68H32a4 4 0 0 0-4 4v112a4 4 0 0 0 4 4Zm68 4a20 20 0 1 0-20 20 20 20 0 0 0 20-20Zm112 0a20 20 0 1 0-20 20 20 20 0 0 0 20-20Zm32-8v-60h-56v40.29A28 28 0 0 1 219.71 188H240a4 4 0 0 0 4-4Z" /></Svg>;
export default Ambulance;