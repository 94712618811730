/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Alarm = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="alarm-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-alarm-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 36a100 100 0 1 0 100 100A100.11 100.11 0 0 0 128 36Zm0 176a76 76 0 1 1 76-76 76.08 76.08 0 0 1-76 76ZM32.49 72.49a12 12 0 1 1-17-17l32-32a12 12 0 1 1 17 17Zm208 0a12 12 0 0 1-17 0l-32-32a12 12 0 1 1 17-17l32 32a12 12 0 0 1 0 17ZM176 124a12 12 0 0 1 0 24h-48a12 12 0 0 1-12-12V88a12 12 0 0 1 24 0v36Z" /></Svg>;
export default Alarm;