/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Angle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="angle-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-angle-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M98 72a6 6 0 0 1 6-6 102.12 102.12 0 0 1 102 102 6 6 0 0 1-12 0 90.1 90.1 0 0 0-90-90 6 6 0 0 1-6-6Zm142 122H78V32a6 6 0 0 0-12 0v34H32a6 6 0 0 0 0 12h34v122a6 6 0 0 0 6 6h168a6 6 0 0 0 0-12Z" /></Svg>;
export default Angle;