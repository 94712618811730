/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Angle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="angle-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-angle-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 40H40a16 16 0 0 0-16 16v144a16 16 0 0 0 16 16h176a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16ZM112 88a64.07 64.07 0 0 1 64 64 8 8 0 0 1-16 0 48.05 48.05 0 0 0-48-48 8 8 0 0 1 0-16Zm88 104H80a8 8 0 0 1-8-8v-80H56a8 8 0 0 1 0-16h16V72a8 8 0 0 1 16 0v104h112a8 8 0 0 1 0 16Z" /></Svg>;
export default Angle;