/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AnchorSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="anchor-simple-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-anchor-simple-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 114h-24a6 6 0 0 0 0 12h17.8a90.13 90.13 0 0 1-83.8 83.78V93.4a30 30 0 1 0-12 0v116.38A90.13 90.13 0 0 1 38.2 126H56a6 6 0 0 0 0-12H32a6 6 0 0 0-6 6 102 102 0 0 0 204 0 6 6 0 0 0-6-6ZM110 64a18 18 0 1 1 18 18 18 18 0 0 1-18-18Z" /></Svg>;
export default AnchorSimple;