/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArrowBendLeftUp = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="arrow-bend-left-up-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-arrow-bend-left-up-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M152 80H56l48-48Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M200 216a88.1 88.1 0 0 1-88-88V88h40a8 8 0 0 0 5.66-13.66l-48-48a8 8 0 0 0-11.32 0l-48 48A8 8 0 0 0 56 88h40v40a104.11 104.11 0 0 0 104 104 8 8 0 0 0 0-16ZM104 43.31 132.69 72H75.31Z" /></Svg>;
export default ArrowBendLeftUp;