/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AnchorSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="anchor-simple-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-anchor-simple-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M152 64a24 24 0 1 1-24-24 24 24 0 0 1 24 24Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M224 112h-24a8 8 0 0 0 0 16h15.64A88.15 88.15 0 0 1 136 207.63V95a32 32 0 1 0-16 0v112.63A88.15 88.15 0 0 1 40.36 128H56a8 8 0 0 0 0-16H32a8 8 0 0 0-8 8 104 104 0 0 0 208 0 8 8 0 0 0-8-8ZM112 64a16 16 0 1 1 16 16 16 16 0 0 1-16-16Z" /></Svg>;
export default AnchorSimple;