/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AirplaneTaxiing = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="airplane-taxiing-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-airplane-taxiing-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M248 136v24a8 8 0 0 1-8 8H61.07a39.75 39.75 0 0 1-38.31-28.51L8.69 92.6A16 16 0 0 1 24 72h8a8 8 0 0 1 5.65 2.34L59.32 96h22.49l-9-26.94A16 16 0 0 1 88 48h8a8 8 0 0 1 5.66 2.34L147.32 96H208a40 40 0 0 1 40 40Zm-40 48a16 16 0 1 0 16 16 16 16 0 0 0-16-16Zm-96 0a16 16 0 1 0 16 16 16 16 0 0 0-16-16Z" /></Svg>;
export default AirplaneTaxiing;