/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AndroidLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="android-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-android-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M180 148a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm-88-16a16 16 0 1 0 16 16 16 16 0 0 0-16-16Zm152 28v24a20 20 0 0 1-20 20H32a20 20 0 0 1-20-20v-22.87a117.35 117.35 0 0 1 33.72-82.44l-22.21-22.2a12 12 0 0 1 17-17L64.3 63.33A114.35 114.35 0 0 1 127.59 44h.41a115.15 115.15 0 0 1 63.89 19.14l23.62-23.63a12 12 0 0 1 17 17l-22 22A115.18 115.18 0 0 1 244 160Zm-24 0a92 92 0 0 0-92.33-92C77.12 68.18 36 110 36 161.13V180h184Z" /></Svg>;
export default AndroidLogo;