/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Alarm = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="alarm-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-alarm-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 42a94 94 0 1 0 94 94 94.11 94.11 0 0 0-94-94Zm0 176a82 82 0 1 1 82-82 82.1 82.1 0 0 1-82 82ZM60.24 36.24l-32 32a6 6 0 1 1-8.48-8.48l32-32a6 6 0 1 1 8.48 8.48Zm176 32a6 6 0 0 1-8.48 0l-32-32a6 6 0 0 1 8.48-8.48l32 32a6 6 0 0 1 0 8.48ZM184 130a6 6 0 0 1 0 12h-56a6 6 0 0 1-6-6V80a6 6 0 0 1 12 0v50Z" /></Svg>;
export default Alarm;