/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Alarm = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="alarm-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-alarm-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 44a92 92 0 1 0 92 92 92.1 92.1 0 0 0-92-92Zm0 176a84 84 0 1 1 84-84 84.09 84.09 0 0 1-84 84ZM58.83 34.83l-32 32a4 4 0 0 1-5.66-5.66l32-32a4 4 0 0 1 5.66 5.66Zm176 32a4 4 0 0 1-5.66 0l-32-32a4 4 0 0 1 5.66-5.66l32 32a4 4 0 0 1 0 5.66ZM188 136a4 4 0 0 1-4 4h-56a4 4 0 0 1-4-4V80a4 4 0 0 1 8 0v52h52a4 4 0 0 1 4 4Z" /></Svg>;
export default Alarm;