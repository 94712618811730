/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AirTrafficControl = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="air-traffic-control-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-air-traffic-control-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232.38 68.52A20 20 0 0 0 216 60h-76V36h12a12 12 0 0 0 0-24h-48a12 12 0 0 0 0 24h12v24H40a20 20 0 0 0-18.8 26.83l26.19 72A20.06 20.06 0 0 0 66.18 172H92v60a12 12 0 0 0 24 0v-60h24v60a12 12 0 0 0 24 0v-60h25.82a20.06 20.06 0 0 0 18.79-13.17l26.19-72a20 20 0 0 0-2.42-18.31ZM114 148l-11.62-64h51.24L142 148ZM45.71 84H78l11.64 64H69ZM187 148h-20.62L178 84h32.27Z" /></Svg>;
export default AirTrafficControl;