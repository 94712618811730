/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Angle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="angle-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-angle-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M100 72a4 4 0 0 1 4-4 100.11 100.11 0 0 1 100 100 4 4 0 0 1-8 0 92.1 92.1 0 0 0-92-92 4 4 0 0 1-4-4Zm140 124H76V32a4 4 0 0 0-8 0v36H32a4 4 0 0 0 0 8h36v124a4 4 0 0 0 4 4h168a4 4 0 0 0 0-8Z" /></Svg>;
export default Angle;