/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AndroidLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="android-logo-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-android-logo-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 160v24a8 8 0 0 1-8 8H32a8 8 0 0 1-8-8v-22.87C24 103.65 70.15 56.2 127.63 56A104 104 0 0 1 232 160Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M176 148a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm-84-12a12 12 0 1 0 12 12 12 12 0 0 0-12-12Zm148 24v24a16 16 0 0 1-16 16H32a16 16 0 0 1-16-16v-22.87a113.38 113.38 0 0 1 35.4-82.41L26.34 53.66a8 8 0 0 1 11.32-11.32L63.82 68.5a111.4 111.4 0 0 1 128.55-.18l26-26a8 8 0 0 1 11.32 11.32L204.82 78.5c.75.71 1.5 1.43 2.24 2.17A111.25 111.25 0 0 1 240 160Zm-16 0a96 96 0 0 0-96-96h-.34C74.91 64.18 32 107.75 32 161.13V184h192Z" /></Svg>;
export default AndroidLogo;