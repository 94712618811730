/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Acorn = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="acorn-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-acorn-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M232 104a56.06 56.06 0 0 0-56-56h-40a24 24 0 0 1 24-24 8 8 0 0 0 0-16 40 40 0 0 0-40 40H80a56.06 56.06 0 0 0-56 56 16 16 0 0 0 8 13.84V128c0 35.53 33.12 62.12 59.74 83.49C103.66 221.07 120 234.18 120 240a8 8 0 0 0 16 0c0-5.82 16.34-18.93 28.26-28.51C190.88 190.12 224 163.53 224 128v-10.16a16 16 0 0 0 8-13.84Zm-77.75 95c-10.62 8.52-20 16-26.25 23.37-6.25-7.32-15.63-14.85-26.25-23.37C77.8 179.79 48 155.86 48 128v-8h160v8c0 27.86-29.8 51.79-53.75 71Z" /></Svg>;
export default Acorn;