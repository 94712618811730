/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AirTrafficControl = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="air-traffic-control-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-air-traffic-control-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M227.47 72A14 14 0 0 0 216 66h-82V30h18a6 6 0 0 0 0-12h-48a6 6 0 0 0 0 12h18v36H40a14 14 0 0 0-13.16 18.78l26.19 72A14 14 0 0 0 66.18 166H98v66a6 6 0 0 0 12 0v-66h36v66a6 6 0 0 0 12 0v-66h31.82a14 14 0 0 0 13.18-9.22l26.19-72A14 14 0 0 0 227.47 72ZM109 154 95.19 78h65.62L147 154Zm-44.7-1.32-26.18-72a2 2 0 0 1 .24-1.83A1.94 1.94 0 0 1 40 78h43l13.82 76H66.18a2 2 0 0 1-1.88-1.32Zm153.58-72-26.18 72a2 2 0 0 1-1.88 1.32h-30.63L173 78h43a1.94 1.94 0 0 1 1.64.85 2 2 0 0 1 .24 1.83Z" /></Svg>;
export default AirTrafficControl;