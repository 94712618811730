/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AirplaneLanding = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="airplane-landing-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-airplane-landing-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M254 216a6 6 0 0 1-6 6H104a6 6 0 0 1 0-12h144a6 6 0 0 1 6 6Zm-23.62-26.22L53.75 140.32A38.14 38.14 0 0 1 26 103.72V48a14 14 0 0 1 18.43-13.29l5.47 1.83a6 6 0 0 1 3.74 3.65l11 30.33L98 80V48a14 14 0 0 1 18.43-13.29l5.47 1.83a6 6 0 0 1 3.63 3.37l22.88 54.53 61.77 17.27A38.09 38.09 0 0 1 238 148.32V184a6 6 0 0 1-7.62 5.78ZM226 148.32a26.07 26.07 0 0 0-19-25l-64.58-18a6 6 0 0 1-3.91-3.46l-23-54.7-2.89-1A2 2 0 0 0 110 48v40a6 6 0 0 1-7.64 5.77l-44-12.54a6 6 0 0 1-4-3.73L43.34 47l-2.71-.9A1.91 1.91 0 0 0 40 46a2 2 0 0 0-1.16.38A2 2 0 0 0 38 48v55.72a26.09 26.09 0 0 0 19 25l169 47.33Z" /></Svg>;
export default AirplaneLanding;