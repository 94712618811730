/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Anchor = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="anchor-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-anchor-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 144c0 38.11-27.67 45.66-49.9 51.72-24.33 6.64-38.1 11.59-38.1 36.28a8 8 0 0 1-16 0c0-24.69-13.77-29.64-38.1-36.28C59.67 189.66 32 182.11 32 144a8 8 0 0 1 16 0c0 24.69 13.77 29.64 38.1 36.28 11.36 3.1 24.12 6.6 33.9 14.34V128H88a8 8 0 0 1 0-16h32V82.83a28 28 0 1 1 16 0V112h32a8 8 0 0 1 0 16h-32v66.62c9.78-7.74 22.54-11.24 33.9-14.34 24.33-6.64 38.1-11.59 38.1-36.28a8 8 0 0 1 16 0Z" /></Svg>;
export default Anchor;