/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AngularLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="angular-logo__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-angular-logo'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m227.08 64.62-96-40a7.93 7.93 0 0 0-6.16 0l-96 40a8 8 0 0 0-4.85 8.44l16 120a8 8 0 0 0 4.35 6.1l80 40a8 8 0 0 0 7.16 0l80-40a8 8 0 0 0 4.35-6.1l16-120a8 8 0 0 0-4.85-8.44Zm-26.45 122.12L128 223.06l-72.63-36.32L40.74 77 128 40.67 215.26 77ZM121 84.12l-40 72a8 8 0 1 0 14 7.76L106 144h44l11 19.88a8 8 0 1 0 14-7.76l-40-72a8 8 0 0 0-14 0ZM141.07 128h-26.14L128 104.47Z" /></Svg>;
export default AngularLogo;