/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AirplaneTaxiing = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="airplane-taxiing-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-airplane-taxiing-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M240 136v24H61.06a32 32 0 0 1-30.65-22.8L16.34 90.3A8 8 0 0 1 24 80h8l24 24h36.91L80.42 66.53A8 8 0 0 1 88 56h8l48 48h64a32 32 0 0 1 32 32Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M208 96h-60.68l-45.66-45.66A8 8 0 0 0 96 48h-8a16 16 0 0 0-15.17 21.06l9 26.94H59.32L37.66 74.34A8 8 0 0 0 32 72h-8A16 16 0 0 0 8.69 92.6l14.07 46.89A39.75 39.75 0 0 0 61.07 168H240a8 8 0 0 0 8-8v-24a40 40 0 0 0-40-40Zm24 56H61.07a23.85 23.85 0 0 1-23-17.1L24 88h4.68l21.66 21.66A8 8 0 0 0 56 112h36.9a8 8 0 0 0 7.59-10.53L88 64h4.68l45.66 45.66A8 8 0 0 0 144 112h64a24 24 0 0 1 24 24Zm-8 48a16 16 0 1 1-16-16 16 16 0 0 1 16 16Zm-96 0a16 16 0 1 1-16-16 16 16 0 0 1 16 16Z" /></Svg>;
export default AirplaneTaxiing;