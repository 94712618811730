/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AirplaneTaxiing = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="airplane-taxiing-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-airplane-taxiing-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 100h-62.34L98.83 53.17A4 4 0 0 0 96 52h-8a12 12 0 0 0-11.37 15.79L87.36 100h-29.7L34.83 77.17A4 4 0 0 0 32 76h-8a12 12 0 0 0-11.48 15.45l14.06 46.89A35.79 35.79 0 0 0 61.06 164H240a4 4 0 0 0 4-4v-24a36 36 0 0 0-36-36Zm28 56H61.06a27.83 27.83 0 0 1-26.81-19.95l-14.07-46.9A4 4 0 0 1 24 84h6.34l22.83 22.83A4 4 0 0 0 56 108h36.91a4 4 0 0 0 3.79-5.26L84.21 65.26A4 4 0 0 1 88 60h6.34l46.82 46.83A4 4 0 0 0 144 108h64a28 28 0 0 1 28 28Zm-16 44a12 12 0 1 1-12-12 12 12 0 0 1 12 12Zm-96 0a12 12 0 1 1-12-12 12 12 0 0 1 12 12Z" /></Svg>;
export default AirplaneTaxiing;