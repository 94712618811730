/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AppStoreLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="app-store-logo-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-app-store-logo-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m62.62 195.05-9.45 16A6 6 0 0 1 42.83 205l9.46-16a6 6 0 1 1 10.33 6.1ZM232 154h-48.94l-31.31-53a6 6 0 1 0-10.33 6.11l61.41 103.93a6 6 0 0 0 10.34-6.1l-23-38.95H232a6 6 0 0 0 0-12Zm-89.53 0H86.88l70.29-118.95A6 6 0 0 0 146.83 29L128 60.82 109.17 29a6 6 0 0 0-10.34 6.1L121 72.62 72.94 154H24a6 6 0 0 0 0 12h118.47a6 6 0 1 0 0-12Z" /></Svg>;
export default AppStoreLogo;