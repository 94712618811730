/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Alien = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="alien-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-alien-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M128 20a92.1 92.1 0 0 0-92 92c0 23.19 12.26 53.33 32.8 80.63C84.58 213.6 108.41 236 128 236s43.42-22.4 59.2-43.37c20.54-27.3 32.8-57.44 32.8-80.63a92.1 92.1 0 0 0-92-92Zm52.8 167.82C162.44 212.23 141.71 228 128 228s-34.44-15.77-52.8-40.18C56 162.24 44 133.19 44 112a84 84 0 0 1 168 0c0 21.19-11.95 50.24-31.2 75.82ZM116 136a36 36 0 0 0-36-36 12 12 0 0 0-12 12 36 36 0 0 0 36 36 12 12 0 0 0 12-12Zm-12 4a28 28 0 0 1-28-28 4 4 0 0 1 4-4 28 28 0 0 1 28 28 4 4 0 0 1-4 4Zm72-40a36 36 0 0 0-36 36 12 12 0 0 0 12 12 36 36 0 0 0 36-36 12 12 0 0 0-12-12Zm-24 40a4 4 0 0 1-4-4 28 28 0 0 1 28-28 4 4 0 0 1 4 4 28 28 0 0 1-28 28Zm-4 44a4 4 0 0 1-4 4h-32a4 4 0 0 1 0-8h32a4 4 0 0 1 4 4Z" /></Svg>;
export default Alien;