/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArrowBendRightDown = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="arrow-bend-right-down-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-arrow-bend-right-down-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m208.49 184.49-48 48a12 12 0 0 1-17 0l-48-48a12 12 0 0 1 17-17L140 195v-67a84.09 84.09 0 0 0-84-84 12 12 0 0 1 0-24 108.12 108.12 0 0 1 108 108v67l27.51-27.52a12 12 0 0 1 17 17Z" /></Svg>;
export default ArrowBendRightDown;