/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AddressBookTabs = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="address-book-tabs-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-address-book-tabs-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 36H48a12 12 0 0 0-12 12v160a12 12 0 0 0 12 12h160a12 12 0 0 0 12-12V48a12 12 0 0 0-12-12Zm-20 64h24v56h-24Zm24-52v44h-24V44h20a4 4 0 0 1 4 4ZM44 208V48a4 4 0 0 1 4-4h132v168H48a4 4 0 0 1-4-4Zm164 4h-20v-48h24v44a4 4 0 0 1-4 4Zm-60.12-45a36.24 36.24 0 0 0-20.44-23.67 28 28 0 1 0-30.88 0A36.28 36.28 0 0 0 76.13 167a4 4 0 0 0 2.87 4.87 3.87 3.87 0 0 0 1 .13 4 4 0 0 0 3.87-3C87 157 99.05 148 112 148s25.05 9 28.12 21a4 4 0 0 0 7.76-2ZM92 120a20 20 0 1 1 20 20 20 20 0 0 1-20-20Z" /></Svg>;
export default AddressBookTabs;