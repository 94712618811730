/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Alarm = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="alarm-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-alarm-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m61.66 37.66-32 32a8 8 0 0 1-11.32-11.32l32-32a8 8 0 0 1 11.32 11.32Zm176 20.68-32-32a8 8 0 0 0-11.32 11.32l32 32a8 8 0 0 0 11.32-11.32ZM224 136a96 96 0 1 1-96-96 96.11 96.11 0 0 1 96 96Zm-32 0a8 8 0 0 0-8-8h-48V80a8 8 0 0 0-16 0v56a8 8 0 0 0 8 8h56a8 8 0 0 0 8-8Z" /></Svg>;
export default Alarm;