/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AlignCenterVerticalSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="align-center-vertical-simple-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-align-center-vertical-simple-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 116h-28V48a20 20 0 0 0-20-20H96a20 20 0 0 0-20 20v68H48a12 12 0 0 0 0 24h28v68a20 20 0 0 0 20 20h64a20 20 0 0 0 20-20v-68h28a12 12 0 0 0 0-24Zm-52 88h-56V52h56Z" /></Svg>;
export default AlignCenterVerticalSimple;