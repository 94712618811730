/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Airplay = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="airplay-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-airplay-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M174.15 210.88A8 8 0 0 1 168 224H88a8 8 0 0 1-6.15-13.12l40-48a8 8 0 0 1 12.29 0ZM208 40H48a24 24 0 0 0-24 24v112a24 24 0 0 0 24 24h20.22a4 4 0 0 0 3.07-1.44l38.28-45.92a24 24 0 0 1 21-8.51 24.68 24.68 0 0 1 16.25 8.94l37.91 45.49a4 4 0 0 0 3.07 1.44H208a24 24 0 0 0 24-24V64a24 24 0 0 0-24-24Z" /></Svg>;
export default Airplay;