/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ApplePodcastsLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="apple-podcasts-logo-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-apple-podcasts-logo-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M159.8 151.82a19.67 19.67 0 0 1 3.58 17.05l-12.18 48A20.17 20.17 0 0 1 131.56 232h-7.12a20.17 20.17 0 0 1-19.64-15.13l-12.18-48a19.67 19.67 0 0 1 3.58-17.05 20.17 20.17 0 0 1 16-7.82h31.5a20.17 20.17 0 0 1 16.1 7.82ZM156 116a28 28 0 1 0-28 28 28 28 0 0 0 28-28Zm26 27a8 8 0 1 0 15.41 4.29 72 72 0 1 0-138.74 0A8 8 0 0 0 74 143a56 56 0 1 1 108 0ZM128 24a104 104 0 0 0-57.82 190.46 8 8 0 1 0 8.9-13.3 88 88 0 1 1 97.84 0 8 8 0 0 0 8.9 13.3A104 104 0 0 0 128 24Z" /></Svg>;
export default ApplePodcastsLogo;