/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Ambulance = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="ambulance-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-ambulance-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M80 120a12 12 0 0 1 12-12h8v-8a12 12 0 0 1 24 0v8h8a12 12 0 0 1 0 24h-8v8a12 12 0 0 1-24 0v-8h-8a12 12 0 0 1-12-12Zm176 0v64a20 20 0 0 1-20 20h-10.06a36 36 0 0 1-67.88 0h-44.12a36 36 0 0 1-67.88 0H32a20 20 0 0 1-20-20V72a20 20 0 0 1 20-20h152a12 12 0 0 1 12 12v4h26.58a19.9 19.9 0 0 1 18.57 12.57l14 35A12 12 0 0 1 256 120Zm-60-28v16h30.28l-6.4-16ZM36 180h10.06a36 36 0 0 1 67.88 0h44.12A36.32 36.32 0 0 1 172 162.08V76H36Zm56 12a12 12 0 1 0-12 12 12 12 0 0 0 12-12Zm112 0a12 12 0 1 0-12 12 12 12 0 0 0 12-12Zm28-12v-48h-36v24.23A36.09 36.09 0 0 1 225.94 180Z" /></Svg>;
export default Ambulance;