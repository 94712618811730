/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AppWindow = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="app-window-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-app-window-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 44H40a12 12 0 0 0-12 12v144a12 12 0 0 0 12 12h176a12 12 0 0 0 12-12V56a12 12 0 0 0-12-12Zm4 156a4 4 0 0 1-4 4H40a4 4 0 0 1-4-4V56a4 4 0 0 1 4-4h176a4 4 0 0 1 4 4ZM76 84a8 8 0 1 1-8-8 8 8 0 0 1 8 8Zm40 0a8 8 0 1 1-8-8 8 8 0 0 1 8 8Z" /></Svg>;
export default AppWindow;