/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ApproximateEquals = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="approximate-equals-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-approximate-equals-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 32H48a16 16 0 0 0-16 16v160a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16Zm-10.8 128.87c-13.07 11.18-24.9 15.1-35.64 15.1-14.26 0-26.62-6.92-37.47-13-18.41-10.31-32.95-18.45-54.89.31a8 8 0 1 1-10.4-12.16c30.42-26 54.09-12.76 73.11-2.11 18.41 10.31 33 18.45 54.89-.31a8 8 0 0 1 10.4 12.16Zm0-56c-13.07 11.18-24.9 15.1-35.64 15.1-14.26 0-26.62-6.92-37.47-13-18.41-10.31-32.95-18.45-54.89.31a8 8 0 0 1-10.4-12.15c30.42-26 54.09-12.76 73.11-2.11 18.41 10.31 33 18.45 54.89-.31a8 8 0 1 1 10.4 12.16Z" /></Svg>;
export default ApproximateEquals;