/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AirplaneTaxiing = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="airplane-taxiing-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-airplane-taxiing-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 88h-59l-44.51-44.49A12 12 0 0 0 96 40h-8a20 20 0 0 0-19 26.33L76.27 88H61L40.49 67.51A12 12 0 0 0 32 64h-8A20 20 0 0 0 4.86 89.75l14.07 46.89A43.72 43.72 0 0 0 61.07 168H240a12 12 0 0 0 12-12v-24a44.05 44.05 0 0 0-44-44Zm20 56H61.07a19.89 19.89 0 0 1-19.16-14.25L30.4 91.36l17.12 17.13A12 12 0 0 0 56 112h36.9a12 12 0 0 0 11.4-15.79l-9.47-28.42 40.69 40.7A12 12 0 0 0 144 112h64a20 20 0 0 1 20 20Zm0 60a20 20 0 1 1-20-20 20 20 0 0 1 20 20Zm-96 0a20 20 0 1 1-20-20 20 20 0 0 1 20 20Z" /></Svg>;
export default AirplaneTaxiing;