/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Anchor = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="anchor__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-anchor'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 136a8 8 0 0 0-8 8c0 24.69-13.77 29.64-38.1 36.28-11.36 3.1-24.12 6.6-33.9 14.34V128h32a8 8 0 0 0 0-16h-32V87a32 32 0 1 0-16 0v25H88a8 8 0 0 0 0 16h32v66.62c-9.78-7.74-22.54-11.24-33.9-14.34C61.77 173.64 48 168.69 48 144a8 8 0 0 0-16 0c0 38.11 27.67 45.66 49.9 51.72 24.33 6.64 38.1 11.59 38.1 36.28a8 8 0 0 0 16 0c0-24.69 13.77-29.64 38.1-36.28 22.23-6.06 49.9-13.61 49.9-51.72a8 8 0 0 0-8-8ZM112 56a16 16 0 1 1 16 16 16 16 0 0 1-16-16Z" /></Svg>;
export default Anchor;