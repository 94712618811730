/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ApplePodcastsLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="apple-podcasts-logo-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-apple-podcasts-logo-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M147.26 140.3a28 28 0 1 0-38.52 0 20.12 20.12 0 0 0-12.54 7.52 19.67 19.67 0 0 0-3.58 17.05l12.18 48A20.17 20.17 0 0 0 124.44 228h7.12a20.17 20.17 0 0 0 19.64-15.13l12.18-48a19.67 19.67 0 0 0-3.58-17.05 20.12 20.12 0 0 0-12.54-7.52ZM108 120a20 20 0 1 1 20 20 20 20 0 0 1-20-20Zm47.63 42.9-12.18 48a12.19 12.19 0 0 1-11.89 9.1h-7.12a12.19 12.19 0 0 1-11.89-9.1l-12.18-48a11.76 11.76 0 0 1 2.14-10.17 12.24 12.24 0 0 1 9.74-4.73h31.5a12.24 12.24 0 0 1 9.74 4.73 11.76 11.76 0 0 1 2.14 10.17ZM188 128a60 60 0 1 0-117.81 16.11 4 4 0 1 1-7.71 2.14 68 68 0 1 1 131 0 4 4 0 0 1-3.85 2.93 3.88 3.88 0 0 1-1.07-.14 4 4 0 0 1-2.79-4.93A60.19 60.19 0 0 0 188 128Zm40 0a99.92 99.92 0 0 1-44.4 83.13 4 4 0 0 1-4.46-6.64 92 92 0 1 0-102.28 0 4 4 0 0 1-4.46 6.64A100 100 0 1 1 228 128Z" /></Svg>;
export default ApplePodcastsLogo;