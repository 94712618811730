/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AnchorSimple = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="anchor-simple-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-anchor-simple-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 116h-24a4 4 0 0 0 0 8h19.91A92.13 92.13 0 0 1 132 211.91V91.71a28 28 0 1 0-8 0v120.2A92.13 92.13 0 0 1 36.09 124H56a4 4 0 0 0 0-8H32a4 4 0 0 0-4 4 100 100 0 0 0 200 0 4 4 0 0 0-4-4ZM108 64a20 20 0 1 1 20 20 20 20 0 0 1-20-20Z" /></Svg>;
export default AnchorSimple;