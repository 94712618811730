/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArrowBendLeftDown = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="arrow-bend-left-down-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-arrow-bend-left-down-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m152 176-48 48-48-48Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M200 24A104.11 104.11 0 0 0 96 128v40H56a8 8 0 0 0-5.66 13.66l48 48a8 8 0 0 0 11.32 0l48-48A8 8 0 0 0 152 168h-40v-40a88.1 88.1 0 0 1 88-88 8 8 0 0 0 0-16Zm-96 188.69L75.31 184h57.38Z" /></Svg>;
export default ArrowBendLeftDown;