/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Aperture = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="aperture-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-aperture-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200.12 55.88A102 102 0 0 0 55.87 200.12 102 102 0 1 0 200.12 55.88Zm-102 66.67 19.65-23.14 29.86 5.46 10.21 28.58-19.65 23.14-29.86-5.46Zm111.81-31.86a90.24 90.24 0 0 1-2 78.63l-56.14-10.24Zm-6.16-11.28-36.94 43.48-30.17-84.47a89.31 89.31 0 0 1 55 25.94 91.33 91.33 0 0 1 12.11 15.05Zm-139.41-15a89.37 89.37 0 0 1 59.45-26.31L143 91.82 54.75 75.71a91.2 91.2 0 0 1 9.61-11.35ZM48 86.68l56.14 10.24-58.07 68.39a90.24 90.24 0 0 1 2-78.63Zm4.21 89.91 36.94-43.48 30.17 84.47a89.31 89.31 0 0 1-55-25.94 91.33 91.33 0 0 1-12.09-15.05Zm139.41 15a89.32 89.32 0 0 1-59.45 26.26L113 164.18l88.24 16.11a91.2 91.2 0 0 1-9.6 11.35Z" /></Svg>;
export default Aperture;