/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AmazonLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="amazon-logo-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-amazon-logo-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M252 168v32a12 12 0 0 1-24 0v-3.09C215.56 208.41 180.25 236 128 236c-64.6 0-103.3-42.18-104.92-44a12 12 0 1 1 17.84-16c.3.33 33.48 36 87.08 36 42.65 0 72.34-22.58 82.87-32H208a12 12 0 0 1 0-24h32a12 12 0 0 1 12 12Zm-96-81.92V84a32 32 0 0 0-58.83-17.45 12 12 0 0 1-20.11-13.1A56 56 0 0 1 180 84v92a12 12 0 0 1-23.85 1.81 56 56 0 1 1-.15-91.73Zm0 45.92a32 32 0 1 0-32 32 32 32 0 0 0 32-32Z" /></Svg>;
export default AmazonLogo;