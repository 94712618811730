/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Ambulance = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="ambulance-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-ambulance-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M82 120a6 6 0 0 1 6-6h18V96a6 6 0 0 1 12 0v18h18a6 6 0 0 1 0 12h-18v18a6 6 0 0 1-12 0v-18H88a6 6 0 0 1-6-6Zm172 0v64a14 14 0 0 1-14 14h-18.6a30 30 0 0 1-58.8 0h-53.2a30 30 0 0 1-58.8 0H32a14 14 0 0 1-14-14V72a14 14 0 0 1 14-14h152a6 6 0 0 1 6 6v10h36.58a13.93 13.93 0 0 1 13 8.8l14 35a6.1 6.1 0 0 1 .42 2.2Zm-64-34v28h49.14l-10.7-26.74a2 2 0 0 0-1.86-1.26ZM32 186h18.6a30 30 0 0 1 58.8 0h53.2a30.1 30.1 0 0 1 15.4-20.52V70H32a2 2 0 0 0-2 2v112a2 2 0 0 0 2 2Zm66 6a18 18 0 1 0-18 18 18 18 0 0 0 18-18Zm112 0a18 18 0 1 0-18 18 18 18 0 0 0 18-18Zm32-8v-58h-52v36.07c.66 0 1.33-.07 2-.07a30.05 30.05 0 0 1 29.4 24H240a2 2 0 0 0 2-2Z" /></Svg>;
export default Ambulance;