/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Angle = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="angle-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-angle-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M200 168v32H72V72h32a96 96 0 0 1 96 96Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M96 72a8 8 0 0 1 8-8 104.11 104.11 0 0 1 104 104 8 8 0 0 1-16 0 88.1 88.1 0 0 0-88-88 8 8 0 0 1-8-8Zm144 120H80V32a8 8 0 0 0-16 0v32H32a8 8 0 0 0 0 16h32v120a8 8 0 0 0 8 8h168a8 8 0 0 0 0-16Z" /></Svg>;
export default Angle;