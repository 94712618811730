/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArrowBendDoubleUpRight = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="arrow-bend-double-up-right__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-arrow-bend-double-up-right'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m229.66 109.66-48 48a8 8 0 0 1-11.32-11.32L212.69 104l-42.35-42.34a8 8 0 0 1 11.32-11.32l48 48a8 8 0 0 1 0 11.32Zm-48-11.32-48-48a8 8 0 0 0-11.32 11.32L156.69 96H128A104.11 104.11 0 0 0 24 200a8 8 0 0 0 16 0 88.1 88.1 0 0 1 88-88h28.69l-34.35 34.34a8 8 0 0 0 11.32 11.32l48-48a8 8 0 0 0 0-11.32Z" /></Svg>;
export default ArrowBendDoubleUpRight;