/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AppleLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="apple-logo-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-apple-logo-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 73.52Zm0 99.26c-16.79-11.53-24-30.87-24-52.78 0-18.3 11.68-34.81 24-46.48C204.53 62.66 185 56 168 56a63.72 63.72 0 0 0-40 14 63.71 63.71 0 0 0-39.12-14C52 55.5 23.06 86.3 24 123.19a119.62 119.62 0 0 0 37.65 84.12A32 32 0 0 0 83.6 216h87.7a31.75 31.75 0 0 0 23.26-10c15.85-17 21.44-33.2 21.44-33.2Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M223.3 169.59a8.07 8.07 0 0 0-2.8-3.4C203.53 154.53 200 134.64 200 120c0-17.67 13.47-33.06 21.5-40.67a8 8 0 0 0 0-11.62C208.82 55.74 187.82 48 168 48a72.23 72.23 0 0 0-40 12.13 71.56 71.56 0 0 0-90.71 9.09A74.63 74.63 0 0 0 16 123.4a127 127 0 0 0 40.14 89.73A39.8 39.8 0 0 0 83.59 224h87.68a39.84 39.84 0 0 0 29.12-12.57 125 125 0 0 0 17.82-24.6c7.02-12.83 6.12-14.83 5.09-17.24Zm-34.63 30.94a23.76 23.76 0 0 1-17.4 7.47H83.59a23.82 23.82 0 0 1-16.44-6.51A111.14 111.14 0 0 1 32 123a58.5 58.5 0 0 1 16.65-42.53A54.81 54.81 0 0 1 88 64h.78A55.45 55.45 0 0 1 123 76.28a8 8 0 0 0 10 0A55.39 55.39 0 0 1 168 64a70.64 70.64 0 0 1 36 10.35c-13 14.52-20 30.47-20 45.65 0 23.77 7.64 42.73 22.18 55.3a105.52 105.52 0 0 1-17.51 25.23ZM128.23 30A40 40 0 0 1 167 0h1a8 8 0 0 1 0 16h-1a24 24 0 0 0-23.24 18 8 8 0 1 1-15.5-4Z" /></Svg>;
export default AppleLogo;