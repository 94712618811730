/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ApplePodcastsLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="apple-podcasts-logo__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-apple-podcasts-logo'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M154.2 138.33a32 32 0 1 0-52.4 0 24.27 24.27 0 0 0-8.76 7 23.68 23.68 0 0 0-4.3 20.49l12.18 48A24.18 24.18 0 0 0 124.44 232h7.12a24.18 24.18 0 0 0 23.52-18.15l12.18-48a23.68 23.68 0 0 0-4.3-20.49 24.27 24.27 0 0 0-8.76-7.03ZM128 104a16 16 0 1 1-16 16 16 16 0 0 1 16-16Zm23.75 57.91-12.18 48a8.18 8.18 0 0 1-8 6.09h-7.12a8.18 8.18 0 0 1-8-6.09l-12.18-48a7.71 7.71 0 0 1 1.42-6.73 8.26 8.26 0 0 1 6.58-3.18h31.5a8.26 8.26 0 0 1 6.58 3.18 7.71 7.71 0 0 1 1.4 6.73ZM72 128a56.31 56.31 0 0 0 2 15 8 8 0 0 1-15.41 4.29 72 72 0 1 1 138.74 0A8 8 0 0 1 182 143a56 56 0 1 0-110-15Zm160 0a103.92 103.92 0 0 1-46.18 86.46 8 8 0 0 1-8.9-13.3 88 88 0 1 0-97.84 0 8 8 0 0 1-8.9 13.3A104 104 0 1 1 232 128Z" /></Svg>;
export default ApplePodcastsLogo;