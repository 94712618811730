/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Anchor = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="anchor-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-anchor-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 138a6 6 0 0 0-6 6c0 26.09-15 31.52-39.58 38.21-12.42 3.39-26.63 7.26-36.42 16.95V126h34a6 6 0 0 0 0-12h-34V85.4a30 30 0 1 0-12 0V114H88a6 6 0 0 0 0 12h34v73.16c-9.79-9.69-24-13.56-36.42-16.95C61 175.52 46 170.09 46 144a6 6 0 0 0-12 0c0 36.58 26.85 43.91 48.42 49.79C107 200.48 122 205.91 122 232a6 6 0 0 0 12 0c0-26.09 15-31.52 39.58-38.21C195.15 187.91 222 180.58 222 144a6 6 0 0 0-6-6ZM110 56a18 18 0 1 1 18 18 18 18 0 0 1-18-18Z" /></Svg>;
export default Anchor;