/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AppleLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="apple-logo-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-apple-logo-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M218.27 169.49C199.86 156.84 196 135.6 196 120c0-19.17 14.25-35.53 22.75-43.57a4 4 0 0 0 0-5.81C207 59.48 186.59 52 168 52a68.3 68.3 0 0 0-40 13 67.61 67.61 0 0 0-87.88 7A70.65 70.65 0 0 0 20 123.3a123.11 123.11 0 0 0 38.9 86.92A35.81 35.81 0 0 0 83.6 220h87.7a35.84 35.84 0 0 0 26.19-11.3 119.93 119.93 0 0 0 17.24-23.79c6.08-11.1 5.42-12.62 4.94-13.72a4 4 0 0 0-1.4-1.7Zm-26.64 33.77A27.83 27.83 0 0 1 171.3 212H83.6a27.84 27.84 0 0 1-19.19-7.6A115.15 115.15 0 0 1 28 123.09a62.55 62.55 0 0 1 17.81-45.43A58.78 58.78 0 0 1 88 60h.84a59.37 59.37 0 0 1 36.66 13.15 4 4 0 0 0 5 0A59.35 59.35 0 0 1 168 60c15.12 0 31.45 5.41 42.11 13.73C200.68 83.42 188 100.16 188 120c0 23.79 8 42.44 23.12 54.17a107.64 107.64 0 0 1-19.49 29.09ZM132.13 31A36 36 0 0 1 167 4h1a4 4 0 0 1 0 8h-1a28 28 0 0 0-27.12 21 4 4 0 0 1-3.88 3 3.87 3.87 0 0 1-1-.13 4 4 0 0 1-2.87-4.87Z" /></Svg>;
export default AppleLogo;