/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Airplane = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="airplane-bold__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-airplane-bold'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M237.37 125.27 164 88.58V48a36 36 0 0 0-72 0v40.58l-73.37 36.69A12 12 0 0 0 12 136v32a12 12 0 0 0 14.35 11.77L92 166.64V179l-12.49 12.51A12 12 0 0 0 76 200v32a12 12 0 0 0 16.46 11.14L128 228.92l35.54 14.22a11.91 11.91 0 0 0 4.46.86 12 12 0 0 0 12-12v-32a12 12 0 0 0-3.51-8.49L164 179v-12.36l65.65 13.13A12 12 0 0 0 244 168v-32a12 12 0 0 0-6.63-10.73ZM220 153.36l-65.65-13.13A12 12 0 0 0 140 152v32a12 12 0 0 0 3.51 8.49L156 205v9.31l-23.54-9.42a12 12 0 0 0-8.92 0L100 214.28V205l12.49-12.48A12 12 0 0 0 116 184v-32a12 12 0 0 0-14.35-11.77L36 153.36v-9.94l73.37-36.69A12 12 0 0 0 116 96V48a12 12 0 0 1 24 0v48a12 12 0 0 0 6.63 10.73L220 143.42Z" /></Svg>;
export default Airplane;