/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AlignRight = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="align-right-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-align-right-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M224 40v176a8 8 0 0 1-16 0V40a8 8 0 0 1 16 0Zm-48 8H80a16 16 0 0 0-16 16v40a16 16 0 0 0 16 16h96a16 16 0 0 0 16-16V64a16 16 0 0 0-16-16Zm0 88H40a16 16 0 0 0-16 16v40a16 16 0 0 0 16 16h136a16 16 0 0 0 16-16v-40a16 16 0 0 0-16-16Z" /></Svg>;
export default AlignRight;