/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AirplaneLanding = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="airplane-landing-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-airplane-landing-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M252 216a4 4 0 0 1-4 4H104a4 4 0 0 1 0-8h144a4 4 0 0 1 4 4Zm-21.08-28.15L54.29 138.4A36.12 36.12 0 0 1 28 103.73V48a12 12 0 0 1 15.79-11.37l5.48 1.82a4 4 0 0 1 2.49 2.44l11.31 31.29L100 82.71V48a12 12 0 0 1 15.79-11.38l5.48 1.82a4 4 0 0 1 2.42 2.25l23.25 55.42 62.7 17.52a36.1 36.1 0 0 1 26.36 34.7V184a4 4 0 0 1-5.08 3.85ZM228 148.33a28.07 28.07 0 0 0-20.51-27l-64.57-18a4 4 0 0 1-2.61-2.31L117 45.47l-3.75-1.25A4 4 0 0 0 108 48v40a4 4 0 0 1-5.1 3.85l-44-12.54a4 4 0 0 1-2.66-2.49L44.9 45.43l-3.64-1.21a3.95 3.95 0 0 0-3.6.55A4 4 0 0 0 36 48v55.72a28.1 28.1 0 0 0 20.45 27l171.55 48Z" /></Svg>;
export default AirplaneLanding;