/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AppWindow = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="app-window-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-app-window-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M216 42H40a14 14 0 0 0-14 14v144a14 14 0 0 0 14 14h176a14 14 0 0 0 14-14V56a14 14 0 0 0-14-14Zm2 158a2 2 0 0 1-2 2H40a2 2 0 0 1-2-2V56a2 2 0 0 1 2-2h176a2 2 0 0 1 2 2ZM78 84a10 10 0 1 1-10-10 10 10 0 0 1 10 10Zm40 0a10 10 0 1 1-10-10 10 10 0 0 1 10 10Z" /></Svg>;
export default AppWindow;