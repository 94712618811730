/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Acorn = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="acorn-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-acorn-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M228 104a52.06 52.06 0 0 0-52-52h-44v-4a28 28 0 0 1 28-28 4 4 0 0 0 0-8 36 36 0 0 0-36 36v4H80a52.06 52.06 0 0 0-52 52 12 12 0 0 0 8 11.3V128c0 33.61 32.29 59.54 58.24 80.37C109.54 220.66 124 232.27 124 240a4 4 0 0 0 8 0c0-7.73 14.46-19.34 29.76-31.63C187.71 187.54 220 161.61 220 128v-12.7a12 12 0 0 0 8-11.3Zm-71.25 98.13c-12.51 10.05-23.23 18.65-28.75 26.79-5.52-8.14-16.24-16.74-28.75-26.79C74.63 182.37 44 157.78 44 128v-12h168v12c0 29.78-30.63 54.37-55.25 74.13ZM216 108H40a4 4 0 0 1-4-4 44.05 44.05 0 0 1 44-44h96a44.05 44.05 0 0 1 44 44 4 4 0 0 1-4 4Z" /></Svg>;
export default Acorn;