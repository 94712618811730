/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ApproximateEquals = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="approximate-equals-thin__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-approximate-equals-thin'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M219.08 155.84a4 4 0 0 1-.53 5.63C203.56 173.9 188.9 180 173.93 180a54.84 54.84 0 0 1-6.12-.34c-15-1.65-28.6-9-41.72-16.14-25.52-13.86-49.62-27-83.54 1.18a4 4 0 0 1-5.1-6.16c17-14.12 33.62-20.07 50.74-18.2 15 1.65 28.6 9 41.72 16.15 25.52 13.86 49.62 26.94 83.54-1.18a4 4 0 0 1 5.63.53ZM42.55 100.69c33.92-28.12 58-15 83.54-1.18 13.12 7.13 26.69 14.5 41.72 16.15a57.32 57.32 0 0 0 6.12.33c15 0 29.63-6.1 44.62-18.53a4 4 0 1 0-5.1-6.16c-33.92 28.13-58 15-83.54 1.18-13.12-7.13-26.69-14.48-41.72-16.14-17.12-1.88-33.72 4.07-50.74 18.19a4 4 0 1 0 5.1 6.16Z" /></Svg>;
export default ApproximateEquals;