/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const ArrowBendDownRight = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="arrow-bend-down-right-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-arrow-bend-down-right-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="m224 152-48 48v-96Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="m229.66 146.34-48-48A8 8 0 0 0 168 104v40h-40a88.1 88.1 0 0 1-88-88 8 8 0 0 0-16 0 104.11 104.11 0 0 0 104 104h40v40a8 8 0 0 0 13.66 5.66l48-48a8 8 0 0 0 0-11.32ZM184 180.69v-57.38L212.69 152Z" /></Svg>;
export default ArrowBendDownRight;