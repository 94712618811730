/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Airplane = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="airplane-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-airplane-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M234.68 130.63 158 92.29V48a30 30 0 0 0-60 0v44.29l-76.68 38.34A6 6 0 0 0 18 136v32a6 6 0 0 0 7.18 5.88L98 159.32v22.19l-14.24 14.25A6 6 0 0 0 82 200v32a6 6 0 0 0 8.23 5.57L128 222.46l37.77 15.11A6 6 0 0 0 174 232v-32a6 6 0 0 0-1.76-4.24L158 181.51v-22.19l72.82 14.56A6 6 0 0 0 238 168v-32a6 6 0 0 0-3.32-5.37ZM226 160.68l-72.82-14.56A6 6 0 0 0 146 152v32a6 6 0 0 0 1.76 4.24L162 202.49v20.65l-31.77-12.71a6 6 0 0 0-4.46 0L94 223.14v-20.65l14.24-14.25A6 6 0 0 0 110 184v-32a6 6 0 0 0-7.18-5.88L30 160.68v-21l76.68-38.34A6 6 0 0 0 110 96V48a18 18 0 0 1 36 0v48a6 6 0 0 0 3.32 5.37L226 139.71Z" /></Svg>;
export default Airplane;