/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AndroidLogo = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="android-logo-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-android-logo-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M174 148a10 10 0 1 1-10-10 10 10 0 0 1 10 10Zm-82-10a10 10 0 1 0 10 10 10 10 0 0 0-10-10Zm146 22v24a14 14 0 0 1-14 14H32a14 14 0 0 1-14-14v-22.87a111.44 111.44 0 0 1 36.28-82.37L27.76 52.24a6 6 0 1 1 8.48-8.48l27.37 27.36a108.59 108.59 0 0 1 64-21.12h.39a109.17 109.17 0 0 1 64.58 20.93l27.18-27.17a6 6 0 0 1 8.48 8.48l-26.31 26.31c1.26 1.15 2.5 2.32 3.72 3.53A109.29 109.29 0 0 1 238 160Zm-12 0a98 98 0 0 0-98-98h-.35C73.81 62.19 30 106.66 30 161.13V184a2 2 0 0 0 2 2h192a2 2 0 0 0 2-2Z" /></Svg>;
export default AndroidLogo;