/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AirplaneTakeoff = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="airplane-takeoff-fill__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-airplane-takeoff-fill'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M176 216a8 8 0 0 1-8 8H24a8 8 0 0 1 0-16h144a8 8 0 0 1 8 8Zm70.31-129.24-18.64-23.89-.12-.15a39.82 39.82 0 0 0-51.28-9.12L124.7 84.38 70.76 64.54a8 8 0 0 0-5.59 0L58 67.27l-.32.13a16 16 0 0 0-4.53 26.47L75 115.06l-20.17 12.2-28.26-9.54a8 8 0 0 0-6.08.4l-3 1.47A16 16 0 0 0 13 145.8l36 35.27.12.12a39.78 39.78 0 0 0 27.28 10.87 40.18 40.18 0 0 0 20.26-5.52l147.41-88a8 8 0 0 0 2.21-11.78Z" /></Svg>;
export default AirplaneTakeoff;