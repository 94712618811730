/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const Aperture = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="aperture-duotone__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-aperture-duotone'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M195.88 60.12a96 96 0 1 0 0 135.76 96 96 0 0 0 0-135.76Zm-55.34 103-36.68-6.69-12.54-35.13 24.14-28.41 36.68 6.69 12.54 35.12Z" opacity={props.duotoneOpacity ?? 0.2} fill={props.duotoneColor ?? "currentColor"} /><Path d="M201.54 54.46A104 104 0 0 0 54.46 201.54 104 104 0 0 0 201.54 54.46Zm-11.31 11.32a88.18 88.18 0 0 1 11 13.48L167.55 119l-27.92-78.22a87.34 87.34 0 0 1 50.6 25ZM155.59 133l-18.16 21.37-27.59-5-9.43-26.37 18.16-21.37 27.59 5ZM65.77 65.78a87.34 87.34 0 0 1 56.66-25.59l17.51 49L58.3 74.32a88 88 0 0 1 7.47-8.54Zm-19.12 95.76a88.41 88.41 0 0 1 2.53-72.62l51.21 9.35Zm19.12 28.68a88.18 88.18 0 0 1-11-13.48L88.45 137l27.92 78.18a87.34 87.34 0 0 1-50.6-24.96Zm124.46 0a87.34 87.34 0 0 1-56.66 25.59l-17.51-49 81.64 14.91a88 88 0 0 1-7.47 8.5Zm-34.62-32.49 53.74-63.27a88.41 88.41 0 0 1-2.53 72.62Z" /></Svg>;
export default Aperture;