/* GENERATED FILE */
import * as React from "react";
import Svg, { Path } from "react-native-svg";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
import type { IconProps } from '../lib'

const AddressBookTabs = ({
  title,
  titleId,
  ...props
}: IconProps & SVGRProps) => <Svg className="address-book-tabs-light__svg-icon-phosphor" testID={props.testID ?? 'phosphor-react-native-address-book-tabs-light'} fill="currentColor" viewBox="0 0 256 256" width={props.size} height={props.size} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<Path d="M208 34H48a14 14 0 0 0-14 14v160a14 14 0 0 0 14 14h160a14 14 0 0 0 14-14V48a14 14 0 0 0-14-14Zm-18 68h20v52h-20Zm20-54v42h-20V46h18a2 2 0 0 1 2 2ZM46 208V48a2 2 0 0 1 2-2h130v164H48a2 2 0 0 1-2-2Zm162 2h-18v-44h20v42a2 2 0 0 1-2 2Zm-58.19-43.49A38 38 0 0 0 131.23 143a30 30 0 1 0-38.45 0 38 38 0 0 0-18.59 23.5 6 6 0 0 0 11.62 3C88.67 158.38 99.93 150 112 150s23.34 8.38 26.19 19.49a6 6 0 0 0 11.62-3ZM94 120a18 18 0 1 1 18 18 18 18 0 0 1-18-18Z" /></Svg>;
export default AddressBookTabs;